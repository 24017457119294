<template>
  <section id="content">
    <PageTitle :title="$t('intrastats_files.title')"></PageTitle>
    <div class="wrapper wrapper-content animated fadeInRight">

      <div class="row">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-title">
              <h2>{{ $t('intrastats_files.checked-files.title') }}</h2>
            </div>
            <div :class="'ibox-content p-md' + (checkedFilesLoading ? ' sk-loading' : '')">

              <div v-if="checkedFilesLoading" class="sk-spinner sk-spinner-double-bounce">
                  <div class="sk-double-bounce1"></div>
                  <div class="sk-double-bounce2"></div>
              </div>

              <b-table 
                  outlined striped
                  :items="checkedFilesProvider"
                  :fields="checkedFilesFields"
                  ref="checkedFiles">
                <template v-slot:cell(options)="row">
                  <a :href="getFileURL(row.item.fileId)"><i class="fa fa-cloud-download"></i></a>
                  <a href="javascript:void(0)" @click="confirmRemoveFile(row.item)"><i class="fa fa-trash-o"></i></a>
                </template>
              </b-table>

            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-title">
              <h2>{{ $t('intrastats_files.generated-files.title') }}</h2>
            </div>
            <div :class="'ibox-content p-md' + (generatedFilesLoading ? ' sk-loading' : '')">

              <div v-if="generatedFilesLoading" class="sk-spinner sk-spinner-double-bounce">
                  <div class="sk-double-bounce1"></div>
                  <div class="sk-double-bounce2"></div>
              </div>

              <b-table 
                  outlined striped
                  :items="generatedFilesProvider"
                  :fields="generatedFilesFields"
                  ref="generatedFiles">
                <template v-slot:cell(options)="row">
                  <a :href="getFileURL(row.item.XLSFile._id)"><i class="fa fa-file-excel-o"></i></a>
                  <a :href="getFileURL(row.item.XMLFile._id)"><i class="fa fa-file-code-o"></i></a>
                  <a href="javascript:void(0)" @click="confirmRemoveDeclaration(row.item)"><i class="fa fa-trash-o"></i></a>
                </template>
              </b-table>

            </div>
          </div>
        </div>
      </div>

      <b-modal ref="removeFileModal" 
          hide-header
          @ok="removeFile">
        {{ 
          $t('intrastats_files.confirm-remove-file',{
            company: checkedFileToRemove.companyName,
            creationDateTime: checkedFileToRemove.formattedCreationDateTime
          })
        }}
      </b-modal>


      <b-modal ref="removeDeclarationModal" 
          hide-header
          @ok="removeDeclaration">
        {{ 
          $t('intrastats_files.confirm-remove-declaration',{
            psiId: declarationToRemove.psiId,
            referencePeriod: declarationToRemove.formattedReferencePeriod
          })
        }}
      </b-modal>

    </div>
  </section>
</template>

<style scoped>
  .b-table .options a:has(i) {
    font-size: 1.3em;
    margin-right: 10px;
  }
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import PageTitle from '../../panels/PageTitle.vue';
import * as api from '@fwk-client/utils/api';
import { messagesTypes, MessageTypes, Message} from '@fwk-client/store/types';

@Component({
  components: {
    PageTitle
  }
})
export default class Files extends mixins(GenericPage) {

  /*
   * Component lifecycle
   */
  mounted() {
    this.updateTableLabels();
  }

  updateTableLabels() {
    this.checkedFilesFields[0].label = this.$t('intrastats_files.checked-files.headers.company') as string;
    this.checkedFilesFields[1].label = this.$t('intrastats_files.checked-files.headers.creation-date-time') as string;
    this.checkedFilesFields[2].label = this.$t('intrastats_files.checked-files.headers.options') as string;

    this.generatedFilesFields[0].label = this.$t('intrastats_files.generated-files.headers.name') as string;
    this.generatedFilesFields[1].label = this.$t('intrastats_files.generated-files.headers.creation-date-time') as string;
    this.generatedFilesFields[2].label = this.$t('intrastats_files.generated-files.headers.party-name') as string;
    this.generatedFilesFields[3].label = this.$t('intrastats_files.generated-files.headers.psi-id') as string;
    this.generatedFilesFields[4].label = this.$t('intrastats_files.generated-files.headers.reference-period') as string;
    this.generatedFilesFields[5].label = this.$t('intrastats_files.generated-files.headers.nb-lines') as string;
    this.generatedFilesFields[6].label = this.$t('intrastats_files.generated-files.headers.options') as string;
  }

  @Watch('$store.state.languages.currentLanguageCode')
  onLanguageChange(to:any, from:any) {
    this.updateTableLabels();
  }

  checkedFilesLoading = false;
  generatedFilesLoading = false;
  
  readonly emptyFile = {};
  readonly emptyDeclaration = {};

  checkedFileToRemove: any = {...this.emptyFile};
  declarationToRemove:any = {...this.emptyDeclaration};

  checkedFilesFields = [
      {
        key: "companyName",
        label: ""
      },
      {
        key: "formattedCreationDateTime",
        label: ""
      },
      {
        key: "options",
        label: "",
        class: "options"
      }
    ];

  checkedFilesProvider(ctx:any) {
    var options:api.ApiVueOptions =  {
      app: this
    }

    return api.getAPI('/api/intrastats/list-checked-files', options).then((response:any) => {
      if(response.checkedFiles) {  
        return response.checkedFiles;
      }
      else {
        return [];
      }
    })
  }

  generatedFilesFields = [
      {
        key: "name",
        label: ""
      },
      {
        key: "formattedCreationDateTime",
        label: ""
      },
      {
        key: "partyName",
        label: ""
      },
      {
        key: "psiId",
        label: ""
      },
      {
        key: "formattedReferencePeriod",
        label: ""
      },
      {
        key: "nbLines",
        label: ""
      },
      {
        key: "options",
        label: "",
        class: "options"
      }
    ];

  generatedFilesProvider(ctx:any) {
    var options:api.ApiVueOptions =  {
      app: this
    }

    return api.getAPI('/api/intrastats/list-generated-files', options).then((response:any) => {
      if(response.declarations) {  
        return response.declarations;
      }
      else {
        return [];
      }
    })
  }

  getFileURL(fileId:any) {
    var url = '/api/intrastats/file/'+fileId+'/download';
    return url;
  }

  onFileRemoved() {
    // @ts-ignore
    this.$refs.checkedFiles.refresh()
  }

  onDeclarationRemoved() {
    // @ts-ignore
    this.$refs.generatedFiles.refresh()
  }

  confirmRemoveFile(checkedFile:any) {
    this.checkedFileToRemove = checkedFile;
    // @ts-ignore
    this.$refs.removeFileModal.show()
  }

  removeFile() {

    var input = {
      "checkedFileID" : this.checkedFileToRemove._id
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.checkedFilesLoading = true;
    
    api.postAPI('/api/intrastats/checked-file/remove', input, options).then((response:any) => {
      if(response.removed) {
        // We update the list of files
        this.onFileRemoved();
      }
      // We reset the file to remove
      this.checkedFileToRemove = {...this.emptyFile};

      this.checkedFilesLoading = false;
    });
  }

  confirmRemoveDeclaration(declaration:any) {
    this.declarationToRemove = declaration;
    // @ts-ignore
    this.$refs.removeDeclarationModal.show()
  }

  removeDeclaration() {

    var input = {
      "declarationID" : this.declarationToRemove._id
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.generatedFilesLoading = true;
    
    api.postAPI('/api/intrastats/declaration/remove', input, options).then((response:any) => {
      if(response.removed) {
        // We update the list of files
        this.onDeclarationRemoved();
      }
      // We reset the declaration to remove
      this.declarationToRemove = {...this.emptyDeclaration};

      this.generatedFilesLoading = false;
    });
  }

}
</script>